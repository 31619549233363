import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  user: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
  message: null,
};

export const LoginUser = createAsyncThunk(
  "user/LoginUser",
  async (user, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://c-greenproject.org:8000/login", // Periksa tanda kutip
        {
          email: user.email,
          password: user.password,
        },
        {
          withCredentials: true, // Pastikan ini disetel jika Anda menggunakan kredensial seperti cookie
        }
      );

      // Simpan token di localStorage
      localStorage.setItem("token", response.data.token);

      return response.data;
    } catch (error) {
      if (error.response) {
        const message = error.response.data.msg;
        return thunkAPI.rejectWithValue(message);
      }
      return thunkAPI.rejectWithValue(error.message); // Menangani error lain yang mungkin terjadi
    }
  }
);

export const getMe = createAsyncThunk("user/getMe", async (_, thunkAPI) => {
  const token = localStorage.getItem("token");

  try {
    const response = await axios.get("https://c-greenproject.org:8000/me", {
      withCredentials: true, // Pastikan untuk mengirim kredensial
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      const message = error.response.data.msg;
      return thunkAPI.rejectWithValue(message);
    }
    return thunkAPI.rejectWithValue(error.message); // Menangani error lain yang mungkin terjadi
  }
});

export const LogOut = createAsyncThunk("user/LogOut", async (_, thunkAPI) => {
  const token = localStorage.getItem("token");

  try {
    await axios.delete("https://c-greenproject.org:8000/logout", {
      withCredentials: true,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Hapus token dari localStorage jika logout berhasil
    localStorage.removeItem("token");
  } catch (error) {
    if (error.response) {
      const message = error.response.data.msg;
      return thunkAPI.rejectWithValue(message);
    }
    return thunkAPI.rejectWithValue(error.message); // Menangani error lain yang mungkin terjadi
  }
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LoginUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(LoginUser.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(getMe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMe.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(getMe.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(LogOut.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(LogOut.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = null; // Reset user setelah logout berhasil
      })
      .addCase(LogOut.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = authSlice.actions;
export default authSlice.reducer;
